import { HttpClient, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private apiUrl = environment.apiUrl;

  constructor(
    private http: HttpClient,
  ) { }

  getProfile(): Observable<any> {
    return this.http.get(`${this.apiUrl}/profile`);
  }

  updateProfile(data: any): Observable<any> {
    return this.http.patch(`${this.apiUrl}/profile`, data);
  }

  getFilterOptions(): Observable<any> {
    return this.http.get(`${this.apiUrl}/public/homepage/get-filter-option`);
  }

  getBrands(params: any): Observable<any> {
    return this.http.get(`${this.apiUrl}/public/homepage/get-brand-list?${params}`);
  }
  
  getBrandsQC(params: any): Observable<any> {
    return this.http.get(`${this.apiUrl}/public/homepage/get-brand-list/auth?${params}`);
  }

  getBrand(id: any, role: string = 'public'): Observable<any> {
    return this.http.get(`${this.apiUrl}/${role}/brand/${id}`);
  }

  getRecommendedBrands(params: any): Observable<any> {
    return this.http.get(`${this.apiUrl}/private/brand/recommendation?${params}`);
  }

  getMultipleBrands(params: any, role: string = 'public'): Observable<any> {
    return this.http.get(`${this.apiUrl}/${role}/brand/multiple-brands?${params}`);
  }

  getOrders(params?: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/private/order?${params}`, {});
  }

  getOrder(uid: any): Observable<any> {
    return this.http.get(`${this.apiUrl}/private/order/${uid}`);
  }

  requestBrand(data: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/public/request-brand`, data);
  }

  getWishlist(): Observable<any> {
    return this.http.get(`${this.apiUrl}/private/wishlist`);
  }

  addToWishlist(data: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/private/wishlist`, data);
  }

  deleteFromWishlist(uid: string): Observable<any> {
    return this.http.delete(`${this.apiUrl}/private/wishlist/${uid}`);
  }

  getPaymentOptions(): Observable<any> {
    return this.http.get(`${this.apiUrl}/private/payment-method`);
  }

  upload(formData: FormData): Observable<any> {
    return this.http.post(`${this.apiUrl}/private/upload`, formData);
  }

  uploadWithProgress(request: HttpRequest<any>): Observable<any> {
    return this.http.request(request);
  }

  /**
   * Management API Endpoints
   *  */ 

  getManagementProfile(): Observable<any> {
    return this.http.get(`${this.apiUrl}/management/profile`);
  }

  getAllProfiles(params?: any): Observable<any> {
    return this.http.get(`${this.apiUrl}/management/profile/all?${params}`);
  }

  updateStatusProfile(id: any, status: boolean): Observable<any> {
    return this.http.patch(`${this.apiUrl}/management/profile/approve-reject/${id}`, { approve: status });
  }

  getManagementBrands(params?: any): Observable<any> {
    return this.http.get(`${this.apiUrl}/management/brand?${params ? params : ''}`);
  }

  getManagementBrand(id: any): Observable<any> {
    return this.http.get(`${this.apiUrl}/management/brand/${id}`);
  }

  updateVerificationStatus(brand: any, status: boolean): Observable<any> {
    return this.http.post(`${this.apiUrl}/management/brand/verified/${brand.id}`, { verified: status });
  }

  getAutoPilot(): Observable<any> {
    return this.http.get(`${this.apiUrl}/private/master/brand-auto-pilot`);
  }

  getBadanHukum(): Observable<any> {
    return this.http.get(`${this.apiUrl}/private/master/brand-badan-hukum`);
  }

  getIndustry(): Observable<any> {
    return this.http.get(`${this.apiUrl}/private/master/brand-industry`);
  }

  getSubIndustry(): Observable<any> {
    return this.http.get(`${this.apiUrl}/private/master/brand-sub-industry`);
  }

  getSize(): Observable<any> {
    return this.http.get(`${this.apiUrl}/private/master/brand-size`);
  }

  getMonthlyFee(): Observable<any> {
    return this.http.get(`${this.apiUrl}/private/master/brand-monthly-fee`);
  }

  getMasterTags(): Observable<any> {
    return this.http.get(`${this.apiUrl}/management/master/package-tag`);
  }
  
  getMasterTag(id: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/management/master/package-tag/${id}`);
  }

  createMasterTag(data: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/management/master/package-tag`, data);
  }

  updateMasterTag(data: any): Observable<any> {
    return this.http.patch(`${this.apiUrl}/management/master/package-tag/${data.id}`, data);
  }

  deleteMasterTag(id: string): Observable<any> {
    return this.http.delete(`${this.apiUrl}/management/master/package-tag/${id}`);
  }

  /**
   * Franchisor Endpoints
   */

  getFranchisorBrands(): Observable<any> {
    return this.http.get(`${this.apiUrl}/private/brand`);
  }

  getFranchisorBrand(id: any): Observable<any> {
    return this.http.get(`${this.apiUrl}/private/brand/${id}`);
  }

  addFranchisorBrand(brand: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/private/brand`, brand);
  }

  updateFranchisorBrand(id: string, brand: any): Observable<any> {
    return this.http.patch(`${this.apiUrl}/private/brand/${id}`, brand);
  }

  deleteFranchisorBrand(id: any): Observable<any> {
    return this.http.delete(`${this.apiUrl}/private/brand/${id}`);
  }

  getFranchisorPackages(params: any): Observable<any> {
    return this.http.get(`${this.apiUrl}/private/package?${params}`);
  }

  getFranchisorPackage(id: any): Observable<any> {
    return this.http.get(`${this.apiUrl}/private/package/${id}`);
  }

  addFranchisorPackage(data: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/private/package`, data)
      .pipe(
        catchError(error => {
          console.error('Error in addFranchisorPackage:', error);
          throw error;
        })
      );
  }

  updateFranchisorPackage(id: string, data: any): Observable<any> {
    return this.http.patch(`${this.apiUrl}/private/package/${id}`, data)
      .pipe(
        catchError(error => {
          console.error('Error in updateFranchisorPackage:', error);
          throw error;
        })
      );
  }

  deleteFranchisorPackage(id: any): Observable<any> {
    return this.http.delete(`${this.apiUrl}/private/package/${id}`);
  }

  duplicateFranchisorPackage(id: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/private/package/duplicate/${id}`, {});
  }

  getPackageIncludes(params?: any): Observable<any> {
    return this.http.get(`${this.apiUrl}/private/package-include?${params}`);
  }

  getPackageInclude(id: any): Observable<any> {
    return this.http.get(`${this.apiUrl}/private/package-include/${id}`);
  }

  createPackageInclude(data: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/private/package-include`, data);
  }

  updatePackageInclude(id: string, data: any): Observable<any> {
    return this.http.patch(`${this.apiUrl}/private/package-include/${id}`, data);
  }

  deletePackageInclude(id: any): Observable<any> {
    return this.http.delete(`${this.apiUrl}/private/package-include/${id}`);
  }

  uploadKTP(formData: FormData): Observable<any> {
    return this.http.post(`${this.apiUrl}/private/uploadKTP`, formData);
  }

  uploadKTPhealthCheck(): Observable<any> {
    return this.http.get(`${this.apiUrl}/private/uploadKTP-health`);
  }

  updateKTP(uid: string,data: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/profile/ktp/${uid}`, data);
  }

  updateLiveStatus(brand: any, status: boolean): Observable<any> {
    return this.http.post(`${this.apiUrl}/private/brand/live/${brand.id}`, { live: status });
  }

  requestBrandVerification(brandId: any): Observable<any> {
    return this.http.get(`${this.apiUrl}/private/brand/request-verification/${brandId}`);
  }

  getTowerLocations(params: any): Observable<any> {
    return this.http.get(`${this.apiUrl}/private/location/tower?${params}`);
  }

  getTowerLocation(uid: any): Observable<any> {
    return this.http.get(`${this.apiUrl}/private/location/tower/${uid}`);
  }

  getProvinces(params: any): Observable<any> {
    return this.http.get(`${this.apiUrl}/private/location/province?${params}`);
  }

  getRegencies(params: any): Observable<any> {
    return this.http.get(`${this.apiUrl}/private/location/regency?${params}`);
  }

  getDistricts(params: any): Observable<any> {
    return this.http.get(`${this.apiUrl}/private/location/district?${params}`);
  }

  getVillages(params: any): Observable<any> {
    return this.http.get(`${this.apiUrl}/private/location/village?${params}`);
  }

  getTags(): Observable<any> {
    return this.http.get(`${this.apiUrl}/private/package-tag`);
  }

  getTag(id: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/private/package-tag/${id}`);
  }

  // Sales Endpoints
  getUserByPhoneNumber(phoneNumber: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/private/quick-checkout/search-user?phoneNumber=${phoneNumber}`);
  }

  createUserQC(data: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/private/quick-checkout/create-user`, data);
  }

  updateUserQC(data: any): Observable<any> {
    return this.http.patch(`${this.apiUrl}/private/quick-checkout/update-user`, data);
  }

  quickCheckout(data: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/private/quick-checkout/checkout`, data);
  }

  createLead(data: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/private/leads`, data);
  }

  getLeads(): Observable<any> {
    return this.http.get(`${this.apiUrl}/private/leads`);
  }

  updateLead(uid: any, data: any): Observable<any> {
    return this.http.put(`${this.apiUrl}/private/leads/${uid}`, data);
  }

  deleteLead(uid: any): Observable<any> {
    return this.http.delete(`${this.apiUrl}/private/leads/${uid}`);
  }

  approveOrderLocation(uid: string): Observable<any> {
    return this.http.post(`${this.apiUrl}/private/order/approve/${uid}`, {});
  }

  rejectOrderLocation(uid: string, reason: string): Observable<any> {
    return this.http.post(`${this.apiUrl}/private/order/reject/${uid}`, {reason});
  }

  fileProxy(url: string): Observable<Blob> {
    return this.http.get(`${this.apiUrl}/private/util/get-proxy-file?filePath=${url}`, {
      responseType: 'blob'
    });
  }

  naturalSearch(query: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/public/brands/search?${query}`);
  }
}
